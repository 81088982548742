<template>
  <div>
    <div class="userAbout">
      <b-modal
        id="editUserData"
        :title="
          emailEdit
            ? `Change Email for ${profile.first_name} ${profile.last_name}`
            : `Change Mobile ${profile.first_name} ${profile.last_name}`
        "
        no-close-on-backdrop
        no-close-on-keyboard
        no-close-on-esc
        hide-header-close
        hide-footer
        size="md"
        centered
        modal-class="editUserData"
      >
        <EditDetails
          :emailEdit="emailEdit"
          @closeModal="$bvModal.hide('editUserData')"
        />
      </b-modal>
      <b-row no-gutters>
        <b-col md="12">
          <div class="mb-4 d-flex align-items-center">
            <i class="fas fa-user mr-2"></i>
            <h3>About</h3>
          </div>
          <span class="btn p-0 m-0 ml-2 text-info" @click="$emit('editProfile')"
            ><i class="fas fa-edit"></i
          ></span>
        </b-col>
      </b-row>
      <b-row align-v="center" align-h="center">
        <b-col md="3">
          <div class="fName font-weight-bolder mb-1">First Name</div>
          <div class="email font-weight-bolder mb-1">Email</div>
          <div class="email font-weight-bolder mb-1">Username</div>
        </b-col>
        <b-col md="3">
          <div v-if="!profile.id">
            <b-skeleton animation="throb" width="50%" class="mb-2"></b-skeleton>
            <b-skeleton animation="throb" width="50%" class="mb-2"></b-skeleton>
            <b-skeleton animation="throb" width="50%" class="mb-2"></b-skeleton>
          </div>
          <div v-else>
            <div class="fName font-weight-bolder mb-1 text-secondary">
              {{ profile.first_name }}
            </div>
            <div
              class="fName font-weight-bolder mb-1 text-secondary d-flex align-items-center"
            >
              {{ user.email }}
              <span
                class="btn p-0 m-0 text-info"
                @click="editEmailOrMobile(true)"
                ><i class="fas fa-edit ml-1"></i
              ></span>
            </div>
            <div class="fName font-weight-bolder mb-1 text-secondary">
              {{ user.username }}
            </div>
          </div>
        </b-col>
        <b-col md="3">
          <div class="lName font-weight-bolder mb-1">Last Name</div>
          <div class="mobile font-weight-bolder mb-1">Mobile</div>
          <div class="mobile font-weight-bolder mb-1">DOB</div>
        </b-col>
        <b-col md="3">
          <div v-if="!profile.id">
            <b-skeleton animation="throb" width="50%" class="mb-2"></b-skeleton>
            <b-skeleton animation="throb" width="50%" class="mb-2"></b-skeleton>
          </div>
          <div v-else>
            <div class="fName font-weight-bolder mb-1 text-secondary">
              {{ profile.last_name || "" }}
            </div>
            <div
              class="fName font-weight-bolder mb-1 text-secondary d-flex align-items-center"
            >
              {{ user.mobile }}
              <span
                class="btn p-0 m-0 text-info"
                @click="editEmailOrMobile(false)"
                ><i class="fas fa-edit ml-1"></i
              ></span>
            </div>
            <div
              class="fName font-weight-bolder mb-1 text-secondary d-flex align-items-center"
            >
              {{ profile.birth_date == null ? "-" : profile.birth_date }}
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
    <div class="userAddress user">
      <b-row>
        <b-col md="12">
          <div class="mb-4 d-flex align-items-center">
            <i class="fas fa-address-card mr-2"></i>
            <h3>Address</h3>
          </div>
        </b-col>
      </b-row>
      <b-row align-v="center">
        <b-col md="3">
          <div class="fName font-weight-bolder mb-1">House/Apartment</div>
          <div class="fName font-weight-bolder mb-1">Street</div>
          <div class="email font-weight-bolder mb-1">City</div>
        </b-col>
        <b-col md="3">
          <div v-if="!profile.id">
            <b-skeleton animation="throb" width="50%" class="mb-2"></b-skeleton>
            <b-skeleton animation="throb" width="50%" class="mb-2"></b-skeleton>
            <b-skeleton animation="throb" width="50%" class="mb-2"></b-skeleton>
          </div>
          <div v-else>
            <div class="fName font-weight-bolder mb-1 text-secondary">
              Apartment
            </div>
            <div class="fName font-weight-bolder mb-1 text-secondary">
              {{ profile.address_street }}
            </div>
            <div class="fName font-weight-bolder mb-1 text-secondary">
              {{ profile.address_city }}
            </div>
          </div>
        </b-col>

        <b-col md="3">
          <div class="fName font-weight-bolder mb-1">State</div>
          <div class="email font-weight-bolder mb-1">Country</div>
          <div class="email font-weight-bolder mb-1">Pincode</div>
        </b-col>

        <b-col md="3">
          <div v-if="!profile.id">
            <b-skeleton animation="throb" width="50%" class="mb-2"></b-skeleton>
            <b-skeleton animation="throb" width="50%" class="mb-2"></b-skeleton>
            <b-skeleton animation="throb" width="50%" class="mb-2"></b-skeleton>
          </div>
          <div v-else>
            <div class="fName font-weight-bolder mb-1 text-secondary">
              {{ profile.address_state }}
            </div>
            <div
              class="fName font-weight-bolder mb-1 text-secondary d-flex align-items-center"
            >
              {{ profile.address_country }}
            </div>
            <div class="fName font-weight-bolder mb-1 text-secondary">
              {{ profile.address_postcode }}
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import EditDetails from "../EditDetails.vue";
export default {
  props: ["profile", "user"],
  components: {
    EditDetails,
  },
  data() {
    return {
      emailEdit: false,
    };
  },
  methods: {
    editEmailOrMobile(status) {
      if (status) {
        this.emailEdit = status;
      } else {
        this.emailEdit = status;
      }
      this.$bvModal.show("editUserData");
    },
  },
};
</script>

<style  scoped>
.userAbout {
  background-color: #ffffff;
  padding: 15px;
  border-radius: 10px;
  min-height: 20vh;
  border-top: 5px solid green;
}

.userAddress {
  background-color: #ffffff;
  padding: 15px;
  border-radius: 10px;
  min-height: 20vh;
  border-top: 5px solid green;
  margin-top: 20px;
}

.userAbout .col-md-12 {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.userAbout .col-md-3 i {
  font-size: 10px !important;
}
.userAbout .col-md-12 h3,
.userAddress .col-md-12 h3 {
  font-size: 20px !important;
  font-weight: 600;
  text-transform: uppercase;
}
.userAbout .col-md-12 h3,
.userAddress address {
  font-size: 16px;
  font-weight: 600;
}
</style>
