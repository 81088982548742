<template>
  <div>
    <div class="editCredentials">
      <div v-if="emailEdit">
        <b-form class="border-0" @submit.prevent="changeEmail()">
          <b-form-group>
            <h6 class="alert alert-success text-center" v-if="responseSuccess.created">
              {{ responseSuccess.created[0] }}
            </h6>
          </b-form-group>
          <b-form-group label="Enter New Email">
            <b-form-input
              class="w-50"
              v-model="email"
              placeholder="johndoe@gmail.com"
              :class="{ 'is-invalid': responseErrors.email }"
            ></b-form-input>
            <span class="text-danger" v-if="responseErrors.email">{{
              responseErrors.email[0]
            }}</span>
          </b-form-group>
          <b-form-group>
            <b-button
              variant="primary"
              type="submit"
              size="sm"
              :disabled="updateEmail.disabled"
              >{{ updateEmail.text }}</b-button
            >
            <b-button
              variant="danger"
              size="sm"
              :disabled="updateEmail.disabled"
              class="ml-3"
              @click="cancel()"
              >Cancel</b-button
            >
          </b-form-group>
        </b-form>
        <p
          class="btn p-0 m-0 text-info"
          :disabled="resendMail.disabled"
          @click="resendEmail()"
          v-if="!countdown"
        >
          {{ resendMail.text }}
        </p>
        <p
          v-else
          :class="countdownTime == 120000 ? 'alert alert-success' : 'alert alert-danger'"
        >
          <vue-countdown
            :time="countdownTime"
            v-slot="{ minutes, seconds }"
            @end="endCountdown"
          >
            {{
              countdownTime == 120000
                ? "Verification link sent, request again after  "
                : "Something went wrong, please try again after "
            }}
            {{
              minutes != 0
                ? minutes + " minutes " + seconds + " seconds."
                : seconds + " seconds."
            }}
          </vue-countdown>
        </p>
      </div>

      <div v-else>
        <b-form class="border-0" @submit.prevent="changeMobile()">
          <b-form-group>
            <h6 class="alert alert-success text-center" v-if="responseSuccess.created">
              {{ responseSuccess.created[0] }}
            </h6>
          </b-form-group>
          <b-form-group label="Enter New Mobile">
            <b-form-input
              class="w-50"
              v-model="mobile"
              placeholder="Enter 10 digit mobile number."
              :class="{ 'is-invalid': responseErrors.mobile }"
            ></b-form-input>
            <span class="text-danger" v-if="responseErrors.mobile">{{
              responseErrors.mobile[0]
            }}</span>
          </b-form-group>
          <b-form-group>
            <b-button
              variant="primary"
              type="submit"
              size="sm"
              :disabled="updateEmail.disabled"
              >{{ updateEmail.text }}</b-button
            >
            <b-button
              variant="danger"
              size="sm"
              :disabled="updateEmail.disabled"
              class="ml-3"
              @click="cancel()"
              >Cancel</b-button
            >
          </b-form-group>
        </b-form>
      </div>

      <div class="verifyMobile" v-if="enterOtp">
        <b-form @submit.prevent="verifyMobile()">
          <b-form-group
            label="One Time Password"
            label-for="input-1"
            id="input-group-1"
            class="d-flex align-items-baseline"
            ><div>
              <b-input
                type="text"
                class="ml-3"
                placeholder="Enter OTP..."
                v-model="otpToSent"
              ></b-input>
              <div>
                <b-button
                  type="submit"
                  variant="primary"
                  id="mobileSubmitBtn"
                  class="mt-2 ml-3"
                  :disabled="verifySms.disabled"
                  >{{ verifySms.text }}</b-button
                >
                <b-button
                  variant="danger"
                  class="mt-2 ml-3"
                  :disabled="verifySms.disabled"
                  @click="cancel()"
                  >Cancel</b-button
                >
              </div>
            </div></b-form-group
          >
        </b-form>
        <p
          class="btn p-0 m-0 text-info"
          :disabled="resendSms.disabled"
          @click="otpSent(true)"
          v-if="!countdown"
        >
          {{ resendSms.text }}
        </p>
        <p
          v-else
          :class="countdownTime == 120000 ? 'alert alert-success' : 'alert alert-danger'"
        >
          <vue-countdown
            :time="countdownTime"
            v-slot="{ minutes, seconds }"
            @end="endCountdown"
          >
            {{
              countdownTime == 120000
                ? "OTP sent successfully, request again after  "
                : "Something went wrong, please try again after "
            }}
            {{
              minutes != 0
                ? minutes + " minutes " + seconds + " seconds."
                : seconds + " seconds."
            }}
          </vue-countdown>
        </p>
        <h6 class="alert alert-success" v-if="mobileVerify">
          Mobile Verified successfully
        </h6>
        <h6 class="alert alert-danger" v-if="mobileVerifyFailed">Wrong OTP!</h6>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import VueCountdown from "@chenfengyuan/vue-countdown";
export default {
  components: {
    VueCountdown,
  },
  props: ["emailEdit"],
  data() {
    return {
      mobileEdit: false,
      countdown: false,
      countdownTime: 120000,
      email: "",
      mobile: "",
      otpToSent: "",
      updateEmail: {
        text: "Submit",
        disabled: false,
      },
      verifySms: {
        text: "Submit",
        disabled: false,
      },
      resendMail: {
        text: "Click here to request another verification link.",
        disabled: false,
      },
      resendSms: {
        text: "Click here to request another OTP.",
        disabled: false,
      },
      enterOtp: false,
      mobileVerify: false,
      mobileVerifyFailed: false,
      responseErrors: {},
      responseSuccess: {},
    };
  },
  methods: {
    endCountdown() {
      this.resendMail.text = "Click here to request another verification link.";
      this.resendMail.disabled = false;
      this.resendSms.text = "Click here to request another OTP.";
      this.resendSms.disabled = false;
      this.countdownTime = 120000;
      this.countdown = false;
    },
    //Email
    changeEmail() {
      this.updateEmail.text = "Please wait...";
      this.updateEmail.disabled = true;
      const url = this.$store.getters.getAPIKey.changeEmail;
      var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (this.email == "") {
        this.responseErrors.email = ["Email field cannot be empty!"];
        return this.clearResponse();
      } else if (!this.email.match(mailformat)) {
        this.responseErrors.email = ["Please enter a valid email address!"];
        return this.clearResponse();
      }
      const updatedEmail = {
        email: this.email,
      };
      axios
        .post(url, updatedEmail)
        .then(() => {
          this.responseSuccess["created"] = [
            `Email changed to ${this.email}. Verification link sent.`,
          ];
          this.resendEmail();
          this.clearResponse();
        })
        .catch((error) => {
          this.responseErrors = error.response.data.errors;
          this.clearResponse();
        });
    },
    async resendEmail() {
      if (!this.responseSuccess["created"]) {
        this.resendMail.text = "Please Wait...";
        this.resendMail.disabled = true;
      }
      const url = this.$store.getters.getAPIKey.reVerifyEmail;
      axios
        .post(url)
        .then(() => {
          if (!this.responseSuccess["created"]) {
            this.countdown = true;
            this.countdownTime = 120000;
          }
        })
        .catch((error) => {
          if (error.response) {
            this.countdown = true;
            this.countdownTime = 60000;
          }
        });
    },

    //Mobile
    changeMobile() {
      this.updateEmail.text = "Please wait...";
      this.updateEmail.disabled = true;
      const url = this.$store.getters.getAPIKey.changeMobile;
      if (this.mobile == "") {
        this.responseErrors.mobile = ["Mobile field cannot be empty!"];
        return this.clearResponse();
      } else if (isNaN(this.mobile) || this.mobile.length < 10) {
        this.responseErrors.mobile = ["Please enter a valid mobile number!"];
        return this.clearResponse();
      }

      const updatedMobile = {
        mobile: "91" + this.mobile,
      };
      axios
        .post(url, updatedMobile)
        .then(() => {
          this.responseSuccess["created"] = [
            `Mobile Number changed to ${this.mobile}. OTP sent.`,
          ];
          this.updateEmail.text = "Submit";

          setTimeout(() => {
            this.responseSuccess = {};
            this.mobileEdit = false;
            this.enterOtp = true;
            this.updateEmail.disabled = false;
            this.otpSent(false);
          }, 3000);

          //   this.clearResponse();
        })
        .catch((error) => {
          this.responseErrors = error.response.data.errors;
          this.clearResponse();
        });
    },

    async otpSent(requestAgain) {
      if (requestAgain) {
        this.resendSms.text = "Please wait...";
        this.resendSms.disabled = true;
      }
      const url = this.$store.getters.getAPIKey.reVerifyMobile;

      axios
        .post(url)
        .then(() => {
          if (requestAgain) {
            this.countdown = true;
            this.countdownTime = 120000;
          }
        })
        .catch((error) => {
          if (error.response) {
            this.countdown = true;
            this.countdownTime = 60000;
          }
        });
    },
    async verifyMobile() {
      this.verifySms.text = "Please Wait...";
      this.verifySms.disabled = true;
      const url = this.$store.getters.getAPIKey.verifyMobile;

      try {
        const response = await axios.post(url, { otp: this.otpToSent });
        if (response.status == 204) {
          this.verifySms.text = "Verified";
          this.otpToSent = "";
          this.mobileVerified = !this.mobileVerified;
          setTimeout(() => {
            this.cancel();
          }, 3000);
        }
      } catch (error) {
        if (error.request) {
          this.verifySms.text = "Verify";
          (this.verifySms.disabled = false(
            (this.mobileVerifyFailed = !this.mobileVerifyFailed)
          )),
            setTimeout(() => {
              this.mobileVerifyFailed = !this.mobileVerifyFailed;
            }, 3000);
        }
      }
    },
    clearResponse() {
      this.updateEmail.text = "Submit";
      setTimeout(() => {
        if (this.responseSuccess.created) {
          this.email = "";
          this.mobile = "";
          this.responseSuccess = {};
          //   this.emailEdit = false;
          //   this.mobileEdit = false;
          this.cancel();
        }
        this.responseErrors = {};
        this.updateEmail.disabled = false;
      }, 3000);
    },
    cancel() {
      this.email = "";
      this.mobile = "";
      this.responseSuccess = {};
      this.responseErrors = {};
      this.mobileEdit = false;
      this.resendMail.text = "Click here to request another verification link.";
      this.resendMail.disabled = false;
      this.resendSms.text = "Click here to request another OTP.";
      this.resendSms.disabled = false;
      this.countdownTime = 120000;
      this.countdown = false;
      this.$emit("closeModal");
    },
  },
};
</script>

<style >
  .editUserData .modal-content {
    border-radius: 20px;
    border: 4px solid #5bc0de;
  }

  .editUserData .modal-content header h5 {
    font-size: 14px;
    font-weight: 900;
  }

  .editCredentials form input {
    height: 40px;
    font-weight: 14px;
    font-weight: 600;
    border-radius: 10px;
  }

  .editCredentials form button {
    font-weight: 14px;
    font-weight: 600;
  }

  .editCredentials form input::placeholder {
    font-size: 12px;
    font-weight: 600;
  }

  .editCredentials p {
    font-size: 14px;
    font-weight: 600 !important;
  }


</style>