<template>
  <div>
    <div class="userprofile mt-2 mb-2">
      <div class="closeBtn mb-2 text-right">
        <p @click="$router.push({ name: 'institute' })" class="btn m-0">
          <i class="fas fa-window-close fa-4x text-danger"></i>
        </p>
      </div>

      <b-container fluid>
        <b-row>
          <b-col md="3">
            <div class="profile">
              <div>
                <b-skeleton-img v-if="fetchData"></b-skeleton-img>
                <div v-else>
                  <b-overlay
                    :show="loadImage"
                    spinner-variant="primary"
                    spinner-type="grow"
                    spinner-small
                    rounded="md"
                  >
                    <b-form-file
                      @change="checkuserImage"
                      style="display: none"
                      v-model="profileImage"
                      ref="profileImage"
                      accept=".jpg, .png, .jpeg"
                    ></b-form-file>
                    <img
                      :src="img"
                      class="img-fluid img-thumbnail"
                      alt="img"
                      v-if="cropperImg == null"
                      @click="selectUserImg"
                    />
                    <cropper v-else :src="cropperImg" @change="change" />
                    <div class="text-enter .mt-2">
                      <b-button
                        v-if="cropperImg != null"
                        variant="info"
                        @click="submitImg"
                        >Upload Image</b-button
                      >
                    </div>
                  </b-overlay>
                </div>
                <div class="mt-2">
                  <b-skeleton
                    animation="throb"
                    v-if="fetchData"
                    width="50%"
                  ></b-skeleton>
                  <p
                    class="name d-flex align-items-center justify-content-center font-weight-bolder"
                    v-else
                  >
                    {{
                      userProfile.first_name != null
                        ? userProfile.first_name +
                          " " +
                          (userProfile.last_name || "")
                        : "Full Name"
                    }}
                  </p>
                  <!-- <b-skeleton v-if="fetchData" animation="throb" width="50%"></b-skeleton>
                  <p class="subInfo" v-else>{{ userInfo.username }}</p> -->
                </div>
              </div>
              <div class="mt-2" v-if="fetchData">
                <b-skeleton
                  animation="throb"
                  v-if="fetchData"
                  width="60%"
                ></b-skeleton>
                <b-skeleton
                  animation="throb"
                  v-if="fetchData"
                  width="45%"
                ></b-skeleton>
                <b-skeleton
                  animation="throb"
                  v-if="fetchData"
                  width="25%"
                ></b-skeleton>
              </div>
              <p class="descriotion" v-else>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </p>
              <div class="userOptions mt-2">
                <div
                  class="mt-2 d-flex justify-content-center"
                  v-if="fetchData"
                >
                  <b-skeleton type="button" animation="throb"></b-skeleton>
                  <b-skeleton type="button" animation="throb"></b-skeleton>
                  <b-skeleton type="button" animation="throb"></b-skeleton>
                </div>
                <b-nav vertical v-else>
                  <b-nav-item
                    :active="activeComponent == userIndex ? true : false"
                    v-for="(user, userIndex) in userOptions"
                    :key="userIndex"
                    @click="switchUserData(user.component, userIndex)"
                  >
                    {{ user.name }}
                  </b-nav-item>
                </b-nav>
              </div>
            </div>
          </b-col>
          <b-col>
            <component
              :is="currentComponent"
              :userProfile="userProfile"
              :profile="userProfile"
              :user="userInfo"
              @profileUpdated="getUserProfile"
              @ProfileCancelled="
                (activeComponent = 0), (currentComponent = 'About')
              "
              @editProfile="switchUserData('EditProfile', null)"
            ></component
          ></b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import About from "./UserOptions/About.vue";
// import Experience from "./UserOptions/Experience.vue";
import Education from "./UserOptions/Education.vue";
import EditProfile from "./UserOptions/EditProfile.vue";
import AdditionalInfo from "./UserOptions/AdditionalInfo.vue";
import MoocCourses from "./UserOptions/MoocCourses.vue";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
export default {
  async created() {
    this.fetchData = true;
    this.cloudStorageRoot = this.$store.getters.getAPIKey.userAttachments;
    await this.$store.getters.getMyProfile;
    this.img =
      this.userProfile.profile_image == null || !this.userProfile.profile_image
        ? "https://monstar-lab.com/global/wp-content/uploads/sites/11/2019/04/male-placeholder-image.jpeg"
        : this.cloudStorageRoot +
          this.userProfile.profile_image.file_path +
          this.userProfile.profile_image.file_name;

    this.fetchData = false;
  },
  components: {
    About,
    // Experience,
    Education,
    AdditionalInfo,
    EditProfile,
    MoocCourses,
    Cropper,
  },
  data() {
    return {
      cloudStorageRoot: null,
      fetchData: false,
      loadImage: false,
      cropperImg: null,
      img: "",
      userOptions: [
        {
          name: "Basic Profile Info",
          component: "About",
        },
        {
          name: "Institutional Profile",
          component: "AdditionalInfo",
        },
        {
          name: "Education",
          component: "Education",
        },
        {
          name: "MOOC Courses",
          component: "MoocCourses",
        },
      ],
      currentComponent: "About",
      activeComponent: 0,
      profileImage: null,
    };
  },
  computed: {
    userProfile() {
      return this.$store.getters.getMyProfile;
    },
    userInfo() {
      return this.$store.getters.getUser;
    },
  },
  methods: {
    checkuserImage($event) {
      const file = $event.target.files[0];
      let img = URL.createObjectURL(file);
      this.cropperImg = img;
    },
    selectUserImg() {
      let fileInputElement = this.$refs.profileImage;
      fileInputElement.$refs.input.click();
    },
    change({ coordinates, canvas }) {
      coordinates;
      let img = canvas.toDataURL();
      this.img = img;
    },
    async submitImg() {
      this.loadImage = true;
      this.cropperImg = null;
      await this.$store.dispatch("storeUserProfile", {
        base64_profile_picture: this.img,
      });
      this.loadImage = false;
    },
    async getUserProfile() {
      this.fetchData = true;
      await this.$store.dispatch("getMyProfile");
      this.currentComponent = "About";
      this.activeComponent = 0;
      this.fetchData = false;
    },
    async getUserDetails() {
      await this.$store.dispatch("getUser");
    },
    switchUserData(component, index) {
      this.currentComponent = component;
      this.activeComponent = index;
    },
  },
};
</script>

<style  scoped>
.userprofile .row .col-md-3 .profile {
  max-width: 270px;
  min-height: 600px;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 15px;
  background-color: white;
  border-top: 5px solid green;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}

.userprofile .row .col-md-3 {
  flex: 0 0 20%;
  max-width: 20%;
}

.userprofile .col-md-3 .profile i {
  font-size: 12px !important;
}
.userprofile .col-md-3 .profile img,
.userprofile .col-md-3 .b-aspect .b-skeleton {
  width: 100%;
  height: 200px;
  cursor: pointer;
  overflow: hidden;
}

.userprofile .col-md-3 .profile p {
  padding: 0px;
  margin: 10px;
}

.userprofile .col-md-3 .profile p.name {
  font-weight: 600;
  text-align: center;
}
.userprofile .col-md-3 .profile .nav .nav-item .nav-link {
  background-color: #1ea2ed;
  color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 5px;
}

.userprofile .col-md-3 .profile .nav .nav-item a.active {
  background-color: #f0efef;
  color: #1ea2ed;
  font-weight: 900;
}

@media only screen and (max-width: 600px) and (orientation: portrait) {
  .userprofile .row .col-md-3 {
    flex: none;
    max-width: none;
  }
}
</style>
