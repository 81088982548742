<template>
  <div>
    <div class="editUserProfile">
      <b-form @submit.prevent="createProfile()" id="profileForm" class="bg-white">
        <b-form-group>
          <h4 class="text-info text-center font-weight-bolder">Edit Profile</h4>
        </b-form-group>

        <!-- <div class="form-group">
          <div v-if="imageUrl != null" class="d-flex">
            <img
              :src="imageUrl"
              alt=""
              class="img-fluid"
              style="width: 200px; height: 200px"
            />
            <div class="d-flex flex-column justify-content-center">
              <b-button
                variant="danger"
                size="sm"
                class="ml-3 text-white"
                @click="removeImage"
              >
                Remove Image
              </b-button>
            </div>
          </div>
          <div v-else>
            <img
              src="https://www.seekpng.com/png/detail/966-9665317_placeholder-image-person-jpg.png"
              alt=""
              class="img-fluid img-thumbnail"
              @click="getFile"
              title="Add Image"
            />
          </div>
        </div> -->
        <div class="row">
          <b-form-group class="col" label="First Name">
            <b-form-input
              v-model="userProfile.first_name"
              :class="{ 'is-invalid': responseErrors.first_name }"
            ></b-form-input>
            <span v-if="responseErrors.first_name" class="text-danger">{{
              responseErrors.first_name[0]
            }}</span>
          </b-form-group>
          <b-form-group class="col" label="Last Name">
            <b-form-input
              v-model="userProfile.last_name"
              :class="{ 'is-invalid': responseErrors.last_name }"
            ></b-form-input>
            <span v-if="responseErrors.last_name" class="text-danger">{{
              responseErrors.last_name[0]
            }}</span>
          </b-form-group>
        </div>
        <b-form-group label="Gender">
          <b-form-radio-group
            v-model="userProfile.gender"
            :class="{ 'is-invalid': responseErrors.gender }"
          >
            <b-form-radio value="male">Male</b-form-radio>
            <b-form-radio value="female">Female</b-form-radio>
            <b-form-radio value="other">Other</b-form-radio>
            <span v-if="responseErrors.gender" class="text-danger">{{
              responseErrors.gender[0]
            }}</span>
          </b-form-radio-group>
        </b-form-group>

        <b-form-group label="Date of Birth">
          <b-form-datepicker
            placeholder="YYYY-MM-DD"
            v-model="userProfile.birth_date"
            :class="{ 'is-invalid': responseErrors.birth_date }"
          ></b-form-datepicker>
          <span v-if="responseErrors.birth_date" class="text-danger">{{
            responseErrors.birth_date[0]
          }}</span>
        </b-form-group>

        <b-form-group label="Address">
          <b-form-input
            v-model="userProfile.address_street"
            placeholder="House No./Sector/Plot/Area"
            :class="{ 'is-invalid': responseErrors.address_street }"
          ></b-form-input>
          <span v-if="responseErrors.address_street" class="text-danger">{{
            responseErrors.address_street[0]
          }}</span>
        </b-form-group>
        <div class="row">
          <b-form-group class="col" label="State">
            <b-form-input
              v-model="userProfile.address_state"
              :class="{ 'is-invalid': responseErrors.address_state }"
            ></b-form-input>
            <span v-if="responseErrors.address_state" class="text-danger">{{
              responseErrors.address_state[0]
            }}</span>
          </b-form-group>

          <b-form-group class="col" label="City">
            <b-form-input
              v-model="userProfile.address_city"
              :class="{ 'is-invalid': responseErrors.address_city }"
            ></b-form-input>
            <span v-if="responseErrors.address_state" class="text-danger">{{
              responseErrors.address_state[0]
            }}</span>
          </b-form-group>
        </div>

        <div class="row">
          <b-form-group class="col" label="Country">
            <b-form-input
              v-model="userProfile.address_country"
              :class="{ 'is-invalid': responseErrors.address_country }"
            ></b-form-input>
            <span v-if="responseErrors.address_country" class="text-danger">{{
              responseErrors.address_country[0]
            }}</span>
          </b-form-group>

          <b-form-group class="col" label="Pincode">
            <b-form-input
              v-model="userProfile.address_postcode"
              :class="{ 'is-invalid': responseErrors.address_postcode }"
            ></b-form-input>
            <span v-if="responseErrors.address_postcode" class="text-danger">{{
              responseErrors.address_postcode[0]
            }}</span>
          </b-form-group>
        </div>

        <b-form-group>
          <b-form-file
            v-model="userProfile.profile_picture"
            :value="userProfile.profile_picture"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
            @change="fileSelect"
            ref="fileInput"
            accept="image/*"
            style="display: none"
          ></b-form-file>
        </b-form-group>
        <b-form-group class="text-center">
          <b-button
            type="submit"
            variant="success"
            size="sm"
            :disabled="create.disabled"
            >{{ create.text }}</b-button
          >
          <b-button
            @click="$emit('ProfileCancelled')"
            variant="danger"
            size="sm"
            class="ml-3"
            :disabled="create.disabled"
            >Cancel</b-button
          >
        </b-form-group>
      </b-form>
    </div>
  </div>
</template>

<script>
export default {
  props: ["profile"],
  created() {
    this.userProfile = {
      first_name: this.profile.first_name,
      last_name: this.profile.last_name,
      gender: this.profile.gender,
      birth_date: this.profile.birth_date,
      address_street: this.profile.address_street,
      address_city: this.profile.address_city,
      address_state: this.profile.address_state,
      address_postcode: this.profile.address_postcode,
      address_country: this.profile.address_country,
    };
  },
  data() {
    return {
      userProfileId: "",
      userProfile: {},
      create: {
        text: "Save",
        disabled: false,
      },
      imageUrl: null,
      responseErrors: {},
      profileCreated: false,
    };
  },
  methods: {
    async createProfile() {
      this.create.text = "Please Wait...";
      this.create.disabled = true;

      let profileCreation = await this.$store.dispatch(
        "storeUserProfile",
        this.userProfile
      );
      if (profileCreation.status) {
        this.create.text = "Submit";
        this.create.disabled = false;
        this.$toast.success("Profile Updated", {
          position: "top",
          duration: 3000,
        });
        this.$emit("profileUpdated");
      }
      if (!profileCreation.status) {
        this.create.text = "Submit";
        this.create.disabled = false;
        this.responseErrors = profileCreation.res;
        this.$toast.error("Please check the inputs", {
          position: "top",
          duration: 3000,
        });
        setTimeout(() => {
          this.responseErrors = {};
        }, 4000);
      }
    },
    removeImage() {
      this.imageUrl = null;
      this.userProfile.profile_picture = null;
    },
    getFile() {
      this.$refs.fileInput.$refs.input.click();
    },
    fileSelect(e) {
      this.userProfile.profile_picture = e.target.files[0];
      this.imageUrl = URL.createObjectURL(this.userProfile.profile_picture);
    },
  },
  async mounted() {
    this.userProfileId = await this.userId;
  },
};
</script>

<style >
.editUserProfile {
  border-top: 5px solid green;
  border-radius: 10px;
}
.editUserProfile img {
  width: 300px;
  height: 200px;
  cursor: pointer;
}
#profileForm input {
  width: 350px;
  height: 40px;
  border-radius: 5px;
}

@media only screen and (max-width: 600px) and (orientation: portrait) {
  #profileForm input {
    width: 100%;
    height: auto;
  }
}
</style>
